define("semente-web-app/models/conteudo", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    idx: _emberData.default.attr(),
    secao: _emberData.default.belongsTo('atividade', {
      async: true
    }),
    html: _emberData.default.belongsTo('html', {
      async: true
    }),
    video: _emberData.default.belongsTo('video', {
      async: true
    }),
    quiz: _emberData.default.belongsTo('quiz', {
      async: true
    }),
    quizComp: _emberData.default.belongsTo('quiz-comp', {
      async: true
    })
  });
  _exports.default = _default;
});