define("semente-web-app/models/help", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    pessoaId: _emberData.default.attr(),
    institutionId: _emberData.default.attr(),
    geolocation: _emberData.default.attr(),
    path: _emberData.default.attr(),
    device: _emberData.default.attr(),
    screen: _emberData.default.attr(),
    browser: _emberData.default.attr(),
    message: _emberData.default.attr()
  });
  _exports.default = _default;
});