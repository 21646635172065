define("semente-web-app/models/plataforma-ano", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    descricao: _emberData.default.attr(),
    idx: _emberData.default.attr(),
    livros: _emberData.default.hasMany('livro', {
      async: true
    }),
    instituicoes: _emberData.default.hasMany('instituicao', {
      async: true
    }),
    segmento: _emberData.default.belongsTo('segmento', {
      async: true
    }),
    aulas: _emberData.default.hasMany('aula', {
      async: true
    }),
    modulo: _emberData.default.belongsTo('modulo', {
      async: true
    })
    //plataformaTurmas: DS.hasMany('plataforma-turma', { async: true })
  });
  _exports.default = _default;
});