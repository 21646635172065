define("semente-web-app/models/aula-recomendada", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    idx: _emberData.default.attr(),
    aula: _emberData.default.belongsTo('aula', {
      async: true
    }),
    instituicao: _emberData.default.belongsTo('instituicao', {
      async: true
    }),
    plataformaAno: _emberData.default.belongsTo('plataforma-ano', {
      async: true
    }),
    plataformaTurma: _emberData.default.belongsTo('plataforma-turma', {
      async: true
    }),
    preRecommended: _emberData.default.attr()
  });
  _exports.default = _default;
});