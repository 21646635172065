define("semente-web-app/routes/webapp", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "semente-web-app/config/environment"], function (_exports, _authenticatedRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    env: _environment.default.APP,
    store: Ember.inject.service(),
    session: Ember.inject.service('session'),
    rootURL: _environment.default.rootURL,
    envnmt: _environment.default.APP,
    goToStepTwo: false,
    userName: null,
    afterModel: function afterModel() {
      if (this.get('session.isAuthenticated')) {
        this.firstAccessVerify();
        //this.transitionTo('/webapp');
      }
    },
    firstAccessVerify: function firstAccessVerify() {
      //always requesting without header 'pessoaid' will retrieve the data from the user which bears the stored token
      var sessionData = this.get('session.data');
      var tok = sessionData.authenticated.access_token;
      var temp = 'Bearer ';
      var userToken = temp.concat(tok);
      var tgt_url = this.get('env.host') + '/' + this.get('env.namespace') + '/pessoas?include=instituicao';
      var that = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest();
        xhr.open('GET', tgt_url);
        xhr.onreadystatechange = handler;
        xhr.responseType = 'json';
        // xhr.withCredentials = true; // does not permit request answer due to cross-origin. Can be activated when in production
        xhr.setRequestHeader('Authorization', userToken);
        xhr.setRequestHeader('Accept', 'application/json');
        xhr.setRequestHeader('content-type', 'application/json');
        xhr.setRequestHeader('data-type', 'application/json');
        xhr.send();
        function handler() {
          if (this.readyState === this.DONE) {
            if (this.status === 200 || this.status === 204) {
              var data = this.response.data;
              var included = this.response.included;
              if (!data) {
                var _temp = JSON.parse(this.response);
                data = _temp.data;
                included = _temp.included;
              }
              // --------------------------------------------------------
              // ------------------- FIRST ACCESS -----------------------
              // --------------------------------------------------------
              var logStorage = localStorage.getItem('person_logged');
              var log = JSON.parse(logStorage);
              if (logStorage) {
                // ------------------------------------ institution param 
                var institutions = included.filter(function (i) {
                  return i.id === log.instituicao_id;
                });
                if (!institutions[0]) {
                  localStorage.clear();
                  window.location.reload(true);
                  return;
                }
                var institutionChangePasswordParam = institutions[0].attributes.trocasenhaobrigatoria;
                // ---------------------------------------- account param
                var accounts = data.filter(function (i) {
                  return i.id === log.id;
                });
                if (!accounts[0]) {
                  localStorage.clear();
                  window.location.reload(true);
                  return;
                }
                var accountChangePasswordParam = accounts[0].attributes.trocousenha;
                // ----------------------------------------- verification
                if (institutionChangePasswordParam === true) {
                  if (accountChangePasswordParam !== 1) window.location = '/firstaccess';
                }
              } else {
                var logData, name, trocousenha, hasTo;
                name = data[0].attributes.name;
                trocousenha = data[0].attributes.trocousenha;
                var hasToVerify = included.filter(function (i) {
                  if (i.attributes) return i.attributes.trocasenhaobrigatoria === true;
                  return false;
                });
                if (hasToVerify[0]) hasTo = true;else hasTo = false;
                if (trocousenha === 0) {
                  logData = '{"name":"' + name + '","trocousenha":"' + trocousenha + '","hasTo":"' + hasTo + '"}';
                  localStorage.setItem('log_data', logData);
                  if (hasTo === true) window.location = '/firstaccess';
                } else localStorage.removeItem('log_data');
              }
              // --------------------------------------------------------
              // --------------------------------------------------------
              // --------------------------------------------------------
              resolve(data);
            } else if (this.status === 400 || this.status === 500) {
              reject(new Error(this.response.error));
            } else if (this.status === 401) {
              localStorage.clear();
              that.get('session').invalidate();
            } else {
              reject(new Error('Failure from server call: [' + this.status + ']'));
            }
          }
        }
      });
    }
  });
  _exports.default = _default;
});