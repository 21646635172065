define("semente-web-app/authorizers/author", ["exports", "ember-simple-auth/authorizers/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    session: Ember.inject.service('session'),
    authorize: function authorize(data, block) {
      var sessionData = this.get('session.data');
      var tok = sessionData.authenticated.access_token;
      var temp = 'Bearer ';
      var userToken = temp.concat(tok);
      block('Authorization', userToken);
    }
  });
  _exports.default = _default;
});