define("semente-web-app/components/link-to/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.LinkComponent.extend({
    attributeBindings: ['data-target', 'lang']
  });
  _exports.default = _default;
});