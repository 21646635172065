define("semente-web-app/models/modulo", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    description: _emberData.default.attr(),
    completion: _emberData.default.attr(),
    habilitado: _emberData.default.attr(),
    coverImage: _emberData.default.attr(),
    dataInscricao: _emberData.default.attr(),
    idx: _emberData.default.attr(),
    autor: _emberData.default.attr(),
    videoId: _emberData.default.attr(),
    duracao: _emberData.default.attr(),
    isDes: _emberData.default.attr(),
    percurso: _emberData.default.belongsTo('percurso', {
      async: true
    }),
    blocos: _emberData.default.hasMany('bloco', {
      async: true
    }),
    historicoComps: _emberData.default.hasMany('historico-comp', {
      async: true
    }),
    atividades: _emberData.default.hasMany('atividade', {
      async: true
    }),
    turmas: _emberData.default.hasMany('turma', {
      async: true
    }),
    pessoas: _emberData.default.hasMany('pessoa', {
      async: true
    }),
    sistema: _emberData.default.belongsTo('sistema', {
      async: true
    }),
    acompanhamentosCursoInstituicao: _emberData.default.hasMany('acompanhamento-curso-instituicao', {
      async: true
    }),
    acompanhamentosInstituicaoComp: _emberData.default.hasMany('acompanhamento-instituicao-comp', {
      async: true
    }),
    aplicacaoAulas: _emberData.default.hasMany('aplicacao-aula', {
      async: true
    }),
    comps: _emberData.default.hasMany('comp', {
      async: true
    }),
    backgroundImage: Ember.computed('coverImage', function () {
      return new Ember.String.htmlSafe("background-image: url('" + this.get('coverImage') + "');");
    }),
    limites: _emberData.default.hasMany('limite-faixa', {
      async: true
    }),
    limitesDominios: _emberData.default.hasMany('limite-faixa-dominio', {
      async: true
    }),
    color: Ember.computed('name', function () {
      if (this.get('idx') == 1) return '--autorrelato';
      if (this.get('idx') == 2 || this.get('idx') == 6) return '--autorrelato';
      if (this.get('idx') == 3) return '--autorrelato';
      if (this.get('idx') == 4) return '--setorpublico';
      if (this.get('idx') == 5) return '--heterorrelato';
    }),
    moduloSegmento: Ember.computed('name', function () {
      if (this.get('idx') == 1) return '--adulto';
      if (this.get('idx') == 2 || this.get('idx') == 6) return '--fundamental';
      if (this.get('idx') == 3) return '--medio';
      if (this.get('idx') == 4) return '--setorpublico';
      if (this.get('idx') == 5) return '--infantil';
    })
  });
  _exports.default = _default;
});