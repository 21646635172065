define("semente-web-app/models/matricula", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    pessoa: _emberData.default.belongsTo('pessoa', {
      async: true
    }),
    modulo: _emberData.default.belongsTo('modulo', {
      async: true
    }),
    dataInscricao: _emberData.default.attr(),
    matriculado: _emberData.default.attr(),
    progresso: _emberData.default.attr(),
    formattedProgress: Ember.computed('progresso', function () {
      return Math.round(this.get('progresso'));
    }),
    stateProgress: Ember.computed('formattedProgress', function () {
      var sp = Math.round(this.get('formattedProgress'));
      if (sp == 0) return 0;else if (sp == 100) return 1;else return 2;
    }),
    moduloId: _emberData.default.attr(),
    statusVideoInicio: _emberData.default.attr(),
    codigoCertificado: _emberData.default.attr(),
    dataConclusao: _emberData.default.attr(),
    dataUltimoProgresso: _emberData.default.attr()
  });
  _exports.default = _default;
});