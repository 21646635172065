define("semente-web-app/analytics", [], function () {
  "use strict";

  Ember.Router.reopen({
    notifyGoogleAnalytics: function () {
      if (typeof ga != 'function') {
        return;
      }
      return ga('send', 'pageview', {
        'page': this.get('url'),
        'title': this.get('url')
      });
    }.on('didTransition')
  });
});