define("semente-web-app/models/questao-comp", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    idx: _emberData.default.attr(),
    tipo: _emberData.default.attr(),
    enunciado: _emberData.default.attr(),
    informativo: _emberData.default.attr(),
    delta: _emberData.default.attr(),
    escala: _emberData.default.belongsTo('escala'),
    quizComp: _emberData.default.belongsTo('quiz-comp', {
      async: true
    }),
    alternativasComp: _emberData.default.hasMany('alternativa-comp', {
      async: true
    })
  });
  _exports.default = _default;
});