define("semente-web-app/models/aplicacao-aula", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    dataFinalizado: _emberData.default.attr(),
    dataInscricao: _emberData.default.attr(),
    finalizado: _emberData.default.attr(),
    atividade: _emberData.default.belongsTo('atividade', {
      async: true
    }),
    pessoa: _emberData.default.belongsTo('pessoa', {
      async: true
    }),
    questionarios: _emberData.default.hasMany('aplicacao-questionario', {
      async: true
    }),
    sugestoes: _emberData.default.hasMany('sugestao', {
      async: true
    }),
    dataTermino: _emberData.default.attr(),
    desenvolvimento: _emberData.default.attr(),
    doAnoAtual: _emberData.default.attr(),
    isHeterorrelato: _emberData.default.attr(),
    turmaAplicadaId: _emberData.default.attr()
  });
  _exports.default = _default;
});