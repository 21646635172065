define("semente-web-app/components/login-coreskills", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    actions: {
      authenticate: function authenticate() {
        this.get('authenticate')();
      },
      passwordVisibility: function passwordVisibility() {
        this.get('passwordVisibility')();
      },
      forgotPass: function forgotPass() {
        this.get('forgotPass')();
      },
      cancelForgot: function cancelForgot() {
        this.get('cancelForgot')();
      },
      submtirOnEnter: function submtirOnEnter() {
        this.get('submtirOnEnter')();
      },
      sendMail: function sendMail() {
        this.get('sendMail')();
      }
    },
    init: function init() {
      this._super();
      this.get('value');
    }
  });
  _exports.default = _default;
});