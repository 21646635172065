define("semente-web-app/initializers/load-bootstrap-config", ["exports", "semente-web-app/config/environment", "ember-bootstrap/config"], function (_exports, _environment, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize( /* container, application */
  ) {
    _config.default.load(_environment.default['ember-bootstrap'] || {});
  }
  var _default = {
    name: 'load-bootstrap-config',
    initialize: initialize
  };
  _exports.default = _default;
});