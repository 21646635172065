define("semente-web-app/models/aplicacao-questionario", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    dataFinalizado: _emberData.default.attr(),
    dataIniciado: _emberData.default.attr(),
    finalizado: _emberData.default.attr(),
    notaSenna: _emberData.default.attr(),
    notaRubrica: _emberData.default.attr(),
    notaSemente: _emberData.default.attr(),
    aplicacaoAula: _emberData.default.belongsTo('aplicacao-aula', {
      async: true
    }),
    atividade: _emberData.default.belongsTo('atividade', {
      async: true
    }),
    pessoa: _emberData.default.belongsTo('pessoa', {
      async: true
    }),
    quiz: _emberData.default.belongsTo('quiz-comp', {
      async: true
    }),
    respostasComp: _emberData.default.hasMany('resposta-comp', {
      asunc: true
    })
  });
  _exports.default = _default;
});