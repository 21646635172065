define("semente-web-app/models/interacao-dominio", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    instituicao: _emberData.default.belongsTo('instituicao', {
      async: true
    }),
    dominio1: _emberData.default.belongsTo('dominio', {
      async: true
    }),
    dominio2: _emberData.default.belongsTo('dominio', {
      async: true
    }),
    quad1: _emberData.default.attr(),
    quad2: _emberData.default.attr(),
    quad3: _emberData.default.attr(),
    quad4: _emberData.default.attr(),
    total: _emberData.default.attr(),
    percent1: _emberData.default.attr(),
    percent2: _emberData.default.attr(),
    percent3: _emberData.default.attr(),
    percent4: _emberData.default.attr(),
    respostas: _emberData.default.attr(),
    matriculas: _emberData.default.attr(),
    ratio: _emberData.default.attr(),
    maiorQuad: _emberData.default.attr(),
    compsRecomendadas: _emberData.default.hasMany('comp-recomendada', {
      asyc: true
    })
  });
  _exports.default = _default;
});