define("semente-web-app/models/alternativa", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    idx: _emberData.default.attr(),
    correta: _emberData.default.attr(),
    texto: _emberData.default.attr(),
    videoId: _emberData.default.attr(),
    respostas: _emberData.default.hasMany('resposta', {
      async: true
    }),
    questao: _emberData.default.belongsTo('questao', {
      async: true
    }),
    estadosVideoAlternativa: _emberData.default.hasMany('estadoVideoAlternativa', {
      async: true
    })
  });
  _exports.default = _default;
});