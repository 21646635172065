define("semente-web-app/models/relatorio-detalhado", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    pessoa: _emberData.default.attr(),
    plataformaAno: _emberData.default.belongsTo('plataforma-ano', {
      async: true
    }),
    plataformaTurma: _emberData.default.belongsTo('plataforma-turma', {
      async: true
    }),
    comps: _emberData.default.hasMany('relatorio-detalhado-comp', {
      async: true
    }),
    percent: Ember.computed('comps', function () {
      var competencias = this.get('comps');
      if (typeof competencias != 'undefined') {
        if (competencias.content.length > 0) {
          var result = 100 * competencias.content.length / 17;
          return result.toFixed(0);
        } else return '0';
      } else return '0';
      return 0;
    })
  });
  _exports.default = _default;
});