define("semente-web-app/models/sugestao", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    idx: _emberData.default.attr(),
    modulo: _emberData.default.belongsTo('modulo', {
      async: true
    }),
    aplicacaoAula: _emberData.default.belongsTo('aplicacao-aula', {
      async: true
    }),
    comp: _emberData.default.belongsTo('comp', {
      async: true
    }),
    coverImage: _emberData.default.attr(),
    backgroundImagepriority: Ember.computed('coverImage', function () {
      return new Ember.String.htmlSafe("background-image: url('" + this.get('coverImage') + "');");
    })
  });
  _exports.default = _default;
});