define("semente-web-app/authenticators/authold", ["exports", "ember-simple-auth/authenticators/base", "fetch", "semente-web-app/config/environment"], function (_exports, _base, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Promise = Ember.RSVP.Promise,
    isEmpty = Ember.isEmpty,
    run = Ember.run,
    emberAssign = Ember.assign,
    merge = Ember.merge,
    computed = Ember.computed;
  var assign = emberAssign || merge;
  var JSON_CONTENT_TYPE = 'application/json';
  var _default = _base.default.extend({
    envnmt: _environment.default.APP,
    serverTokenEndpoint: Ember.computed('envnmt', function () {
      //this will define if the server endpoint for the API calls are relative or absolute. Dev - absolute API endpoint; Prod - relative
      return this.get('envnmt.host') + '/' + this.get('envnmt.namespace') + '/auth/login';
    }),
    rejectWithXhr: computed.deprecatingAlias('rejectWithResponse', {
      id: "ember-simple-auth.authenticator.reject-with-xhr",
      until: '2.0.0'
    }),
    rejectWithResponse: false,
    restore: function restore(data) {
      return this._validate(data) ? Promise.resolve(data) : Promise.reject();
    },
    authenticate: function authenticate(identification, password, life) {
      var _this = this;
      return new Promise(function (resolve, reject) {
        var useResponse = _this.get('rejectWithResponse');
        var data = 'UserName=' + identification + '&password=' + password + '&grant_type=password&KeepLogged=' + life;
        _this.makeRequest(data).then(function (response) {
          if (response.ok) {
            response.json().then(function (json) {
              if (_this._validate(json)) {
                var _json = json;
                run(null, resolve, _json);
              } else {
                run(null, reject, "Check that server response includes a token");
              }
            });
          } else {
            if (useResponse) {
              run(null, reject, response);
            } else {
              response.json().then(function (json) {
                return run(null, reject, json);
              });
            }
          }
        }).catch(function (error) {
          return run(null, reject, error);
        });
      });
    },
    invalidate: function invalidate() {
      return Promise.resolve();
    },
    makeRequest: function makeRequest(data) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var url = options.url || this.get('serverTokenEndpoint');
      var requestOptions = {};
      var body = data;
      assign(requestOptions, {
        body: body,
        method: 'POST',
        headers: {
          'Accept': 'application/json, text/javascript',
          'Content-Type': JSON_CONTENT_TYPE,
          'Data-Type': 'json'
        }
      });
      assign(requestOptions, options || {});
      return (0, _fetch.default)(url, requestOptions);
    },
    _validate: function _validate(data) {
      return !isEmpty(data['access_token']);
    }
  });
  _exports.default = _default;
});