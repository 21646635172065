define("semente-web-app/services/asset-loader", ["exports", "ember-asset-loader/services/asset-loader", "ember-asset-loader/errors/asset-load", "semente-web-app/config/environment"], function (_exports, _assetLoader, _assetLoad, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.RETRY_LOAD_SECRET = RETRY_LOAD_SECRET;
  _exports.default = void 0;
  function RETRY_LOAD_SECRET() {}
  var _default = _assetLoader.default.extend({
    rooturl: _environment.default.rootURL,
    loadAsset: function loadAsset(_ref, retryLoad) {
      var _this = this;
      var uri = _ref.uri,
        type = _ref.type;
      var new_uri = uri.replace(/\\/g, '/');
      if (this.get('rooturl') === "/") {} else {
        new_uri = '/' + this.get('rooturl').replace(/\//g, "") + new_uri;
      }
      var cacheKey = "".concat(type, ":").concat(new_uri);
      var cachedPromise = this._getFromCache('asset', cacheKey, retryLoad === RETRY_LOAD_SECRET);
      if (cachedPromise) {
        return cachedPromise;
      }
      var loader = this._getAssetLoader(type);
      var assetPromise = loader(new_uri);
      var assetWithFail = assetPromise.then(function () {
        return {
          new_uri: new_uri,
          type: type
        };
      }, function (error) {
        throw new _assetLoad.default(_this, {
          new_uri: new_uri,
          type: type
        }, error);
      });
      return this._setInCache('asset', cacheKey, assetWithFail);
    }
  });
  _exports.default = _default;
});