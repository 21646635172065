define("semente-web-app/router", ["exports", "semente-web-app/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('login');
    //this.route('maintenance');
    this.route('firstaccess'), this.route('webapp', function () {
      this.mount('semente-engine', {
        path: '/'
      });
    });
    this.route('modulos', function () {
      this.route('modetails');
    });
    this.route('firstaccess');
  });
  var _default = Router;
  _exports.default = _default;
});