define("semente-web-app/controllers/login", ["exports", "semente-web-app/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    rootURL: _environment.default.rootURL,
    envnmt: _environment.default.APP,
    session: Ember.inject.service('session'),
    makeCustomCall: function makeCustomCall(verb, url, json) {
      var header = localStorage.getItem('person_logged');
      var header_person;
      if (header) {
        header = JSON.parse(header);
        header_person = header.id;
      } else {
        header_person = "";
      }
      var sessionData = this.get('session.data');
      var tok = sessionData.authenticated.access_token;
      var temp = 'Bearer ';
      var userToken = temp.concat(tok);
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest();
        xhr.open(verb, url);
        xhr.responseType = 'json';
        xhr.onreadystatechange = handler;
        // xhr.withCredentials = true; // does not permit request answer due to cross-origin. Can be activated when in production
        xhr.setRequestHeader('Accept', 'application/json');
        xhr.setRequestHeader('content-type', 'application/json');
        xhr.setRequestHeader('data-type', 'application/json');
        xhr.setRequestHeader('pessoaid', header_person);
        xhr.setRequestHeader('Authorization', userToken);
        xhr.send(json);
        function handler() {
          if (this.readyState === this.DONE) {
            if (this.status === 200 || this.status === 204) {
              resolve(this.response);
            } else if (this.status === 404) {
              //server unable to answer
              reject('Server not found');
            } else if (this.status >= 400) {
              reject(new Error(this.response.error));
            } else {
              reject(new Error('Failure from server call: [' + this.status + ']'));
            }
          }
        }
      });
    },
    capsLockDetection: window.addEventListener("keydown", function (e) {
      if (navigator.vendor != 'Apple Computer, Inc.') {
        var caps = e.getModifierState && e.getModifierState('CapsLock');
        var msg = document.getElementById('msg-caps-on');
        if (msg != null) {
          if (caps) msg.classList.add('form__msg--on');else msg.classList.remove('form__msg--on');
        }
      }
    }),
    loadComponent: Ember.computed('model', function () {
      return window.location.href.includes('coreskills');
    }),
    actions: {
      passwordVisibility: function passwordVisibility() {
        if (document.querySelector('.login__show-pass')) {
          document.querySelector('.login__show-pass').classList.toggle('login__show-pass--is-show');
        }
        if (document.querySelector('.login-ps__wrapper__show-pass')) {
          document.querySelector('.login-ps__wrapper__show-pass').classList.toggle('login-ps__wrapper__show-pass--is-show');
        }
        if (document.querySelector('.login__box-coreskills--show-pass')) {
          document.querySelector('.login__box-coreskills--show-pass').classList.toggle('login__show-pass--is-show');
        }
        var password = document.getElementById('password');
        if (password.type == 'password') password.type = 'text';else password.type = 'password';
      },
      authenticate: function authenticate() {
        var _this = this;
        this.set('errorMessage', false);
        document.getElementById('login_button').disabled = true;
        this.set('authenticating', true);
        function trim(stringToTrim) {
          return stringToTrim.replace(/^\s+|\s+$/g, "");
        }
        var life = 0;
        //if (document.getElementById('remember').checked) life = 1;
        var username = document.getElementById('identification').value;
        var password = trim(document.getElementById('password').value);
        if (this.get('session.isAuthenticated')) {
          localStorage.clear();
          this.get('session').invalidate();
          return;
        }
        this.get('session').authenticate('authenticator:authold', username, password, life).then(function () {}).catch(function (reason) {
          if (reason.error_description) {
            _this.set('errorMessage', reason.error_description);
            _this.set('success_mail', '');
            // if(this.get('loadComponent')) document.getElementById('login_button').innerHTML = '<span></span> Acessar';
            // else document.getElementById('login_button').innerHTML = 'Entrar';
          } else if (reason) {
            _this.set('errorMessage', reason.error || reason);
            _this.set('success_mail', '');
          } else {
            _this.set('errorMessage', 'Erro desconhecido');
            _this.set('success_mail', '');
          }
          _this.set('authenticating', false);
          document.getElementById('login_button').disabled = false;
        });
      },
      forgotPass: function forgotPass() {
        var email = document.getElementById('identification').value;
        this.set('user_email', '');
        if (email) {
          if (email.length > 4 && email.search('@') > 3) {
            this.set('user_email', email);
          }
        }
        this.set('error_forgot', '');
        this.set('success_mail', '');
        this.set('errorMessage', '');
        document.getElementById('forgot_pass').classList.add('modal--is-show');
        var emailInput = document.getElementById('user_email');
        setTimeout(function () {
          emailInput.focus();
        }, 250);
      },
      cancelPass: function cancelPass() {
        document.getElementById('forgot_pass').classList.remove('modal--is-show');
        document.getElementById('user_email').value = '';
        this.set('user_email', '');
        this.set('success_mail', '');
        this.set('error_forgot', '');
      },
      sendMail: function sendMail() {
        var _this2 = this;
        var mail = document.getElementById('user_email').value;
        if (mail.length < 5 || mail.search('@') < 2) {
          this.set('error_forgot', 'Favor inserir endereço de e-mail válido');
        } else {
          var final_url = this.get('envnmt.host') + '/' + this.get('envnmt.namespace') + '/' + 'accounts/resetPassword';
          var string = JSON.stringify({
            'data': {
              'id': '1',
              'type': 'email',
              'attributes': {
                'email': mail
              }
            }
          });
          var that = this;
          this.makeCustomCall('POST', final_url, string).then(function () {
            that.set('user_email', '');
            that.set('error_forgot', '');
            that.set('success_mail', Ember.String.htmlSafe('<p>Enviamos sua nova senha para este endereço de e-mail.</p></br><p> Caso ele esteja cadastrado, você receberá em sua caixa de entrada. </p>'));
            _this2.set('errorMessage', '');
            document.getElementById('forgot_pass').classList.remove('modal--is-show');
          }).catch(function (error) {
            that.set('error_forgot', '');
            //that.set('error_forgot', 'Erro do servidor: ' + error);
            that.set('success_mail', Ember.String.htmlSafe('<p>Enviamos sua nova senha para este endereço de e-mail.</p></br><p>Caso ele esteja cadastrado, você receberá em sua caixa de entrada. </p>'));
            _this2.set('errorMessage', '');
          });
        }
      }
    }
  });
  _exports.default = _default;
});